import React, { Component } from 'react'
// import data from 'https://the-forma.ru/data.json'
import logo from './images/logo.svg'
// import * as bootstrap from 'bootstrap'

class App extends Component {
  componentDidMount() {
    fetch("https://api.npoint.io/a5548ff262fb14381fa4")
      .then(response => response.json())
      .then(data => this.setState({ data }));
    // let test1 = '1331316'
    // let test2 = "LR076697"
    // for(var propt in data) {
    //   data[propt].upsale.includes(test2) ? console.log(propt) : console.log(1)
    // }
    // data[test1].upsale.includes(test2)  ? console.log(propt) : console.log('hz')
    // const checkCode = obj => obj.code === 'LR094611E';
    // const checkUpsale = obj => obj.upsale.includes('LR076697');
    // const iterator = data.values()
    // console.log(checkCode, data.some(checkCode))
    // console.log(checkUpsale, data.some(checkUpsale))
    // for (const value of iterator) {
    //   if (value.upsale.includes('LR076697')) {console.log(value.code)}
    // }
  }

  constructor(props) {
    super(props);
    this.state = {
      data : [],
      code : ''
    }
    this.updateInput = this.updateInput.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }
  updateInput(event) {
    this.setState({code : event.target.value.toUpperCase()})
  }
  // handleSubmit(){
    // console.log('Your input value is: ' + this.state.code)
    //Send state to the server code
  // }
  render() {
    const data = this.state.data
    const findDetail = [...new Set(data.filter(e => e.code.includes(this.state.code)).concat(data.filter(e => e.manID.includes(this.state.code))).concat(data.filter(e => e.upsale?.includes(this.state.code))))]
    return (
      <div className="section d-flex align-items-center min-vh-100">
        <div className='container'>
          <div className="row">
            <div className="col-12 col-md-6 offset-md-3">
              <div className="row justify-content-center">
                <img src={logo} className="img-fluid mx-auto" alt="Overlord" />
              </div>
              <div className='text-center mb-4'>
                <div className='mx-auto'>
                  <h4><i className="fa-solid fa-location-dot"></i> Москва, Лихоборская наб., 3, стр. 9</h4>
                </div>
                <div className='mx-auto'>
                  <h4><a href="https://api.whatsapp.com/send?phone=79031366565"><i className="fa-brands fa-whatsapp link-success" style={{transform:'scale(1.3)'}}></i></a>
                  <i className="fa-solid fa-phone ms-3"></i> <a href="tel:+74991366565" style={{textDecoration:'none'}}>+7 (499) 136-65-65</a></h4>
                </div>
              </div>
              <div className="alert alert-primary d-flex align-items-center" role="alert">
                <i className="fa-solid fa-info-circle me-3"></i>
                <div>
                  Поиск запчастей on-line по номеру
                </div>
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-magnifying-glass fa-bounce"></i></span>
                <input type="text" className="form-control" onChange={this.updateInput} placeholder="Введите номер" aria-label="Username" aria-describedby="basic-addon1" />
              </div>
              <div className="list-group">
              {/* <input type="submit" onClick={this.handleSubmit} ></input> */}
              {
                this.state.code.length < 4 ?
                  <div>Для поиска укажите не менее 4 символов.</div>
                :
                (findDetail && findDetail.length > 0) ?
                    findDetail.map((item, index) => {
                      return (
                        <div className="list-group-item list-group-item-action" aria-current="true" key={index}>
                          <div className="d-flex w-100 justify-content-between">
                            <h4 className="mb-1 fw-light">{ item.name }</h4>
                            <h4 style={{whiteSpace: 'nowrap'}}><i className="fa-solid fa-barcode"></i> { item.code }</h4>
                          </div>
                          <h6 className="mb-1 fw-light"><i className="fa-solid fa-industry"></i> Изготовитель: <b>{ item.manufacturer }</b></h6>
                          <h6 className="mb-1 fw-light"><i className="fa-solid fa-ruble-sign me-1"></i> Цена: <b>{ item.price }</b> руб.</h6>
                        </div>
                        )
                    })
                // :
                // (findManID && findManID.length > 0) ?
                //     findManID.map((item, index) => {
                //       return (
                //         <div className="list-group-item list-group-item-action" aria-current="true" key={index}>
                //           <div className="d-flex w-100 justify-content-between">
                //             <h4 className="mb-1 fw-light">{ item.name }</h4>
                //             <h4 style={{whiteSpace: 'nowrap'}}><i className="fa-solid fa-barcode"></i> { item.code }</h4>
                //           </div>
                //           <h6 className="mb-1 fw-light"><i className="fa-solid fa-industry"></i> Изготовитель: <b>{ item.manufacturer }</b></h6>
                //           <h6 className="mb-1 fw-light"><i className="fa-solid fa-ruble-sign me-1"></i> Цена: <b>{ item.price }</b> руб.</h6>
                //         </div>
                //         )
                //     })
                // :
                // (findUpsale && findUpsale.length > 0) ?
                //     findUpsale.map((item, index) => {
                //       return (
                //         <div className="list-group-item list-group-item-action" aria-current="true" key={index}>
                //           <div className="d-flex w-100 justify-content-between">
                //             <h4 className="mb-1 fw-light">{ item.name }</h4>
                //             <h4 style={{whiteSpace: 'nowrap'}}><i className="fa-solid fa-barcode"></i> { item.code }</h4>
                //           </div>
                //           <h6 className="mb-1 fw-light"><i className="fa-solid fa-industry"></i> Изготовитель: <b>{ item.manufacturer }</b></h6>
                //           <h6 className="mb-1 fw-light"><i className="fa-solid fa-ruble-sign me-1"></i> Цена: <b>{ item.price }</b> руб.</h6>
                //         </div>
                //         )
                //     })
                : <div>По вашему запросу ничего не найдено..</div> }
              </div>
              <div className="row text-center">
                <a href="https://overlordmotors.ru/" target="_blank" rel="noreferrer"><div className="btn btn-secondary my-3">Вернуться на главную</div></a>
              </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default App;
